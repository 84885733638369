import React, { useState, useEffect } from 'react';
import './assets/css/PageEmailForm.css';

function PageEmailForm ( {email, setEmail, submitTime} ) {
  const [message, setMessage] = useState({ text: '', type: '', hidden: true });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage({ text: 'Please enter an email address.', type: 'warning', hidden: false });
      return;
    }

    try {
      const response = await fetch('/api/update_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          submit_time: submitTime,
        }),
      });
      const data = await response.json();

      if (data.success) {
        setMessage({ text: 'Thanks for your submission!', type: 'success', hidden: false });
      } else if (data.error) {
        setMessage({ text: "This email has already been registered, please check it again.", type: 'error', hidden: false });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ text: 'An error occurred. Please try again.', type: 'error', hidden: false });
    }
  };

  useEffect(() => {
    if (!message.hidden) {
      const timer = setTimeout(() => {
        setMessage((prevMessage) => ({
          ...prevMessage,
          hidden: true
        }));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message.hidden]);

  return (
    <div className="page-email-form">
      <div className="div-blank-large-top" />
      <div className="div-blank-large-top" />
      <p className="p-margin">
        <div className="div-text-area">
          <span className="span-text-italic-email">
            Please enter your Email to receive Promo Code for additional in-app features along with more detailed information on the app!
          </span>
        </div>
      </p>
      <div className="div-blank-small" />
      <form className="form-container-email" onSubmit={handleSubmit}>
        <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} className="email-input" />
        <span className="span-spacing">　</span>
        <button type="submit" className="signup-button">Sign Up</button>
      </form>
      <div className={`message ${message.type}`} style={{ visibility: message.hidden ? 'hidden' : 'visible' }}>
          {message.text}
      </div>
      <div className="div-blank-large-bottom" />
    </div>
  );
}

export default PageEmailForm;

  // const PageEmailForm = () => {
  //   return (
      // <div className="page-email-form">
      //   <div className="div-blank-small-top" />
      //   <p>
      //     <div className="div-text-area">
      //       <span className="span-text-italic-email">
      //         Please enter your Email to receive Promo Code for additional in-app features along with more detailed information on the app!
      //       </span>
      //     </div>
      //   </p>
      //   <div className="form-container">
      //     <input type="email" placeholder="Enter your email" className="email-input" />
      //     <span className="span-spacing">　</span>
      //     <button className="signup-button">Sign Up</button>
      //   </div>
      //   <div className="div-blank-small-bottom" />
      // </div>
  //   );
  // };


