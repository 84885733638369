import React from 'react';
import './assets/css/Page.css';
import Figure_GuideInformation from './assets/images/Figure02_GuideInformation.png';
import Figure_GuideMirror from './assets/images/Figure03_GuideMirror.png';
import Figure_GuideSearchResults from './assets/images/Figure04_GuideSearchResults.png';
import Figure_Guide3DModeling from './assets/images/Figure05_Guide3DModeling.png';
import VideoInteraction from './assets/videos/Video02_Interaction.mp4';

const Page05 = () => {
  return (
    <div className="page-default">
      <div className="div-title">
        <p className="p-margin">
          <span className="span-title-text">
            Guide to Using Our Platform
          </span>
        </p>
      </div>
      <div className="div-blank-medium" />
      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-subtitle-text">
            Immersive Shopping through Extended Reality (XR):
          </span>
          <div className='div-blank-small'/>
          <span className="span-sub-desc-text">
            <ul className="ul-check">
              <li>
                Automatic Body Measurement: Stand in front of the mirror, and your body measurements are automatically captured.
              </li>
            </ul>
          </span>
        </p>
      </div>

      <img src={Figure_GuideInformation} alt="Figure_GuideInformation" className="image-center-1" />
      
      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-sub-desc-text">
          <ul className="ul-check">
              <li>
                AI-Driven Recommendations: AI suggests a variety of clothing styles tailored to your body shape.
              </li>
            </ul>
          </span>
        </p>
      </div>
      
      <img src={Figure_GuideMirror} alt="Figure_GuideMirror" className="image-center-2" />

      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-sub-desc-text">
          <ul className="ul-check">
              <li>
                Explore and Search: Browse styles based on your preferences or search for specific items.
              </li>
            </ul>
          </span>
        </p>
      </div>

      <img src={Figure_GuideSearchResults} alt="Figure_GuideSearchResults" className="image-center-3" />

      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-sub-desc-text">
          <ul className="ul-check">
              <li>
                View 3D Models: Select items to view realistic 3D models, or submit product detail page links from external websites to convert them into 3D models.
              </li>
            </ul>
          </span>
        </p>
      </div>

      <img src={Figure_Guide3DModeling} alt="Figure_Guide3DModeling" className="image-center-4" />

      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-sub-desc-text">
          <ul className="ul-check">
              <li>
                Virtual Try-On: Virtually try on 3D-modeled clothing for a lifelike fitting experience and save your favorites to “Virtual Closet.”
              </li>
            </ul>
          </span>
        </p>
      </div>

      <video className="video-center-2" autoPlay loop muted playsInline>
                <source src={VideoInteraction} type="video/mp4" />
                Your browser does not support the video tag.
      </video>

      <div className="div-blank-large" />
    </div>
  );
};

export default Page05;