import React, { useState, useEffect } from 'react';
import './App.css';
import PageSurvey from './components/PageSurvey';
import PageHeader from './components/PageHeader';
import Page01 from './components/Page01';
import Page02 from './components/Page02';
import Page04 from './components/Page04';
import Page05 from './components/Page05';
import Page06 from './components/Page06';
import Page07 from './components/Page07';
import PageEmailForm from './components/PageEmailForm';
import PageFooter from './components/PageFooter';

function App() {
  const [userSurveyInfo, setUserSurveyInfo] = useState(null);
  const [email, setEmail] = useState('');
  const [submitTime, setSubmitTime] = useState('');

  const handleUserSurveyComplete = (selectedOption, submitTime) => {
    setUserSurveyInfo(selectedOption);
    setSubmitTime(submitTime);
  };

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
  });

  return (
    <div className="App">
      <PageHeader />
      {!userSurveyInfo ? (
        <PageSurvey onComplete={handleUserSurveyComplete} />
      ) : (
        <>
          <Page01 />
          <Page02 />
          <PageEmailForm email={email} setEmail={setEmail} submitTime={submitTime} />
          <Page04 />
          <Page05 />
          <Page06 />
          <Page07 />
          <div className="div-blank-medium" />
          <div className="div-blank-medium" />
          <PageEmailForm email={email} setEmail={setEmail} submitTime={submitTime} />
          <div className="div-blank-large" />
          <div className="div-blank-medium" />
          <PageFooter />
        </>
      )}
    </div>
  );
}

export default App;
