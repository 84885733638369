import React from 'react';
import './assets/css/PageHeader.css';
import logo from './assets/images/Figure01_ShoppyXR_Logo_Name.png';  

const MoveToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

function PageHeader() {
  return (
    <div className="header-bar">
      <div className="div-logo">
        <img src={logo} alt="ShoppyXR Logo" className="header-logo" onClick={MoveToTop} />
      </div>
    </div>
  );
}

export default PageHeader;