import React from 'react';
import './assets/css/PageFooter.css';
import logo from './assets/images/Figure01_ShoppyXR_Logo.png';  

function PageFooter() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
            <div className='div-text-title'>
                <p className='p-margin-non'>
                    ShoppyXR <img className='imgae-footer-left' src={logo} alt="ShoppyXR Logo"/>
                    </p>
            </div>
        </div>
        <div className="footer-center">
            <div className='div-text-title'>
            <p className='p-margin-non'>LEGAL</p>
            </div>
          <div className="div-text-sub">
            <p className='p-margin-non'><a className='a-tag-legal' href="#!">Terms & Conditions</a></p>
            <div className='div-footer-blank-small' />
            <p className='p-margin-non'><a className='a-tag-legal' href="#!">Privacy Policy</a></p>
          </div>
        </div>
        <div className="footer-right">
            <div className='div-text-title'>
              <p className='p-margin-non'>CONTACT</p>
            </div>
          <div className='div-text-sub'>
            <p className='p-margin-non'><a className='a-tag-contact' href="mailto:ShoppyXR@gmail.com" target="_self">ShoppyXR@gmail.com</a></p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className='div-text-corp'>
            <p className='p-margin-non'>© 2024 by SXRcorp.</p>
        </div>
      </div>
    </footer>
  );
}

export default PageFooter;