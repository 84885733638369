import React from 'react';
import './assets/css/Page.css';

const Page04 = () => {
  return (
    <div className="page-default">
      <div className="div-title">
        <p className="p-margin">
          <span className="span-title-text">Key Features</span>
        </p>
      </div>
      <div className="div-blank-medium" />
      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-subtitle-text">
            Experience Virtual Try-On
          </span>
          <div className="div-blank-small"/>
          <span className="span-sub-desc-text">
            Look in the mirror and try on realistic 3D-modeled clothes that fit your body, adjust sizes, and explore multiple brands simultaneously.
          </span>
        </p>
      </div>
      <div className="div-blank-medium"/>
      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-subtitle-text">
            Automated 3D Model Generation
          </span>
          <div className="div-blank-small"/>
          <span className="span-sub-desc-text">
            Automatically transform 2D product images into realistic 3D models, bringing products to life instantly.
          </span>
        </p>
      </div>
      <div className="div-blank-medium"/>
      <div className="div-sub-title">
        <p className="p-margin">
          <span className="span-subtitle-text">
            Personalized AI-powered recommendations 
          </span>
          <div className="div-blank-small"/>
          <span className="span-sub-desc-text">
            AI recommends clothes tailored to your preferences and measured body shape, ensuring the perfect fit and style.
          </span>
        </p>
      </div>
      <div className="div-blank-large" />
    </div>
  );
};

export default Page04;
