import React from 'react';
import './assets/css/Page.css';
import Figure_Community from './assets/images/Figure06_Community.png';
import Figure_CommunityShare from './assets/images/Figure07_Community.png'

const Page07 = () => {
  return (
    <div className="page-default">
        <div className="div-sub-title">
          <p className="p-margin">
          <span className="span-subtitle-text">
            Community:
          </span>
          <div className="div-blank-small" />
          <span className="span-sub-desc-text">
              <ul className="ul-check">
                  <li>
                    Follow your friends or other users to stay updated on their latest style choices and virtual try-on experiences.
                  </li>
                  <br/>
                  <li>
                    Discover fashion styles uploaded by other users and instantly try on their 3D-modeled clothes in your size for a personalized fit.
                  </li>
              </ul>
          </span>
          </p>
      </div>

      <img src={Figure_Community} alt="Figure_Community" className="image-center-5" />

      <div className="div-sub-title">
          <p className="p-margin">
          <span className="span-sub-desc-text">
              <ul className="ul-check">
                  <li>
                    Connect with friends live, choose outfits together, and virtually try them on, sharing the excitement of shopping as if you were in the same store.
                  </li>
              </ul>
          </span>
          </p>
      </div>

      <img src={Figure_CommunityShare} alt="Figure_CommunityShare" className="image-center-6" />

      <div className="div-blank-medium" />
    </div>
  );
};

export default Page07;
