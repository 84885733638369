import React from 'react';
import './assets/css/Page.css';
import VideoGeneral from './assets/videos/Video01_General.mp4';

const Page01 = () => {
    return (
        <div className="page-default">
            <div className="div-title">
                <p className="p-margin">
                    <span className="span-title-text">Your Offline Shopping Experience, Now in Our XR APP</span>
                </p>
            </div>
            <div className="div-blank-medium" />
            <video className="video-center-1" autoPlay loop muted playsInline>
                <source src={VideoGeneral} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="div-blank-medium" />
            <div className="div-blank-small" />
        </div>
    );
};

export default Page01;