import React, { useState } from 'react';
import './assets/css/PageSurvey.css';

function PageSurvey({ onComplete }) {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOption) {
      alert('Please select an option.');
      return;
    }

    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_survey: selectedOption
        }),
      });

      const data = await response.json();
      if (response.ok) {
        onComplete(selectedOption, data.SubmitTime);
      } else {
        alert('Failed to save data.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="AppQuestion">
      <div className="content-question">
        <div className="div-question">
          <p className='p-margin'>
            Which XR headset are you currently using?
          </p>
        </div>

        <form className="form-container" onSubmit={handleSubmit}>
          <div className="radio-form">
            <div className="radio-option">
              <input type="radio" id="apple" name="headset" value="Apple Vision Pro" onChange={handleOptionChange} />
              <label htmlFor="apple">Apple Vision Pro</label>
            </div>
            <div className="radio-option">
              <input type="radio" id="meta" name="headset" value="Meta Quest" onChange={handleOptionChange} />
              <label htmlFor="meta">Meta Quest</label>
            </div>
            <div className="radio-option">
              <input type="radio" id="both" name="headset" value="Both" onChange={handleOptionChange} />
              <label htmlFor="both">Both</label>
            </div>
            <div className="radio-option">
              <input type="radio" id="none" name="headset" value="None" onChange={handleOptionChange} />
              <label htmlFor="none">None</label>
            </div>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
        
        
      </div>
    </div>
  );
}

export default PageSurvey;
