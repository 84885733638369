import React from 'react';
import './assets/css/Page.css';

const Page02 = () => {
  return (
    <div className="page-default">
      {/* <div className="div-blank-medium" /> */}
      {/* <div className="div-blank-medium" />
      <div className="div-blank-medium" />
      <div className="div-blank-medium" /> */}
      <div className="div-title">
        <p className="p-margin">
          <span className="span-title-text-orign">Say goodbye to the worries of refunds & style regrets!</span>
        </p>
        <div className="div-blank-small" />
        <p className="p-margin">
          <span className="span-desc-text">
            Discover a new shopping experience with lifelike Virtual Try-On, AI-driven style recommendations tailored to your body shape, and the ability to compare and mix items from multiple brands.
          </span>
        </p>
      </div>
    </div>
  );
};

export default Page02;
