import React from 'react';
import './assets/css/Page.css';

const Page06 = () => {
    return (
        <div className="page-default">
            <div className="div-sub-title">
                <p className="p-margin">
                <span className="span-subtitle-text">
                    Virtual Closet:
                </span>
                <div className="div-blank-small" />
                <span className="span-sub-desc-text">
                    <ul className="ul-check">
                        <li>
                            In “Virtual Closet,” you can view and virtually try on all the items you've saved.
                        </li>
                        <br/>
                        <li>
                            Discover your style by mixing and matching items from various brands, all in one place — no need to visit multiple stores like traditional offline shopping.
                        </li>
                        <br/>
                        <li>
                            Capture photos or videos of the styles you love while virtually trying on clothes, so you can easily remember them or share with others.
                        </li>
                    </ul>
                </span>
                </p>
            </div>
            <div className="div-blank-large" />
        </div>
    );
};

export default Page06;